<template>
  <div
    class="document-library px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Document Library" size="large" />
    <Paragraph
      ><p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2016-In-Process-Hazard-Classification.pdf?alt=media&token=2283c042-1abd-4e29-9d13-740bbfc68769"
          >“In-Process Hazard Classification of Explosives”</a
        >. This paper gives a general procedure to classify the hazards of an
        energetic material at various stages of processing. The procedure
        incorporates standard DoD and DOT tests where applicable and other tests
        accepted by industry. PDF format, 2016.
      </p>
      <p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/SBAT-Paper-and-Presentation.pdf?alt=media&token=76910c53-9865-43a4-890e-11a9f518feb4"
          target="_new"
          >“SBAT Paper and Presentation”</a
        >. This paper and presentation give information on the advantages of
        using the SBAT to determine thermal properties of substances. PDF
        format, July 2010 (1.1 MB).
      </p>
      <p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/GoDetect-ESD-Presentation.pdf?alt=media&token=dfeebdb2-d309-48e5-b25e-597a425a3428"
          target="_new"
          >“GoDetect-ESD<sup>TM</sup> Presentation”</a
        >. This presentation given at the
        <a
          title="2011 ET User's Group Conference"
          href="http://www.etusersgroup.org/poctober-2011-meeting"
          target="_blank"
          >2011 ET User’s Group Conference</a
        >
        gives a brief synopsis of the
        <router-link title="GoDetect-ESD link" to="/test-equipment/godetect"
          >GoDetect-ESD<sup>TM</sup> software system</router-link
        >. PDF format, October 2011 (700 KB).
      </p>
      <p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/Standard-Required-Information-Form-0515.pdf?alt=media&token=2c4bfda0-8c46-4f2c-9e3b-0e337422135a"
          target="_new"
          >“Standard Required Information for Testing”</a
        >. This form lists the basic information that must be provided to SMS
        for testing consideration. PDF format, September 2002 (74 KB).
      </p>
      <p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/SMS%20Capabilities%20Summary.pdf?alt=media&token=c603a344-d86a-44ed-8be5-35b205bfda94"
          target="_new"
          >“SMS Capabilities Summary”</a
        >. A three-page summary of our capabilities in PDF format, April 2002
        (77 KB).
      </p>
      <p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/Data_Guides.pdf?alt=media&token=3f3c397b-7986-4b49-bcdb-81e0f08b4a6b"
          target="_new"
          >“Data Guides”</a
        >. Over the years, a series of data guides and technical notes have been
        published as a service to our customers and associates. We have updated
        these documents and included additional information about our services
        in PDF format, July 1999 (7.3 MB).
      </p>
      <p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/Chart-Significance-Method.pdf?alt=media&token=3a41fa9f-c6ac-45a4-9bfd-4a4256777829"
          target="_new"
          >“Chart Significance Method and PROBIT Comparisons”</a
        >. A summary of SMS’s developed Chart Significance Method and PROBIT
        comparisons in PDF format, January 2012 (1.0 MB).
      </p>
      <p>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/SubcontractTCs.pdf?alt=media&token=4a116d9b-b68e-4c27-b294-7ea0bf7ad50e"
          target="_new"
          >“SMS General Clauses and Provisions”</a
        >. SMS contracting details and specifics, SMS Form SMSC-001, June 25,
        2015. (560 KB).
      </p>
      <p>
        A list of other
        <router-link
          title="Technical Presentations"
          to="/resources/technical-presentations"
          >Technical Presentations</router-link
        >
        is available here.
      </p>
    </Paragraph>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Document Library",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "A library of documents on In-Process Hazard Classification, SBAT testing, GoDetect, SMS capabilities summary, and more."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.document-library {
  a {
    color: #aa222c;
  }
}
</style>
